@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./variables.scss";

@font-face {
  font-family: "IBM";
  src: url("../font/IBMPlexSansThai-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "IBM";
  src: url("../font/IBMPlexSansThai-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "IBM";
  src: url("../font/IBMPlexSansThai-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "IBM";
  src: url("../font/IBMPlexSansThai-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "IBM";
  src: url("../font/IBMPlexSansThai-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "IBM";
  src: url("../font/IBMPlexSansThai-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "IBM";
  src: url("../font/IBMPlexSansThai-Bold.ttf") format("truetype");
  font-weight: 700;
}

body {
  @apply antialiased;
  background-color: $base-bg-color;
  background-image: radial-gradient(
    at top center,
    $gradiant-bg-top 0%,
    $gradiant-bg-bottom 100%
  );
  color: $base-text-color;
  font-family: "IBM";

  // default text size
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.line {
  background: $line;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

select {
  // remove default arrow down
  -webkit-appearance: none;
  appearance: none;
  // add custom arrow down
  background-image: url("../../assets/svg/arrow-down.svg");
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}

// FOR HAPPY HOUR BUTTON
/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  // color: rgb(255, 255, 255);
  // background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  // background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
