// Base Variables
$base-text-color: #fff;
$base-color: red;
$base-bg-color: #0a1025;
$gradiant-bg-top: #21264a;
$gradiant-bg-bottom: #1b1b1b;
$hover-shadow: rgba(0, 0, 0, 0.25);
$line: #148f2d;

$success: #12c48b;
$danger: #e56a66;
$warning: #ffc107;

$white-75: rgba(255, 255, 255, 0.75);
